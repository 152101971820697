<template>
  <div v-if="bannerHtml" class="gridbox-element" bg="xs" :style="`order:${randomNum};`">
    <div
      class="box"
      direction="column"
      valign="center"
      halign="center"
      extent="w-100 h-100"
      :style="globalAdvertise.getAdvertise('game-square', false)"
      v-html="bannerHtml"
    />
  </div>
</template>

<script lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '~/store/global'

export default {
  props: {
    max: {
      type: Number,
      default () {
        return 0
      }
    }
  },
  setup (props) {

    // 글로벌 스토어
    const { loader } = storeToRefs(useGlobalStore())

    let randomNum: any = ref(0)
    let bannerHtml: any = ref('')
    function getRandomNum () {
      randomNum.value = Math.floor(Math.random() * props.max) * 100
    }
    getRandomNum()

    // 메모리 비우기
    function restore () {
      randomNum.value = null
      bannerHtml.value = null
    }

    // 
    onBeforeUnmount (() => {
      restore()
    })

    return {
      restore,
      loader,
      bannerHtml,
      randomNum,
      getRandomNum
    }
  },
  watch: {
    'loader' (to, from) {
      if (!to && from) {
        this.getRandomNum()
        this.insertBanner()
      }
    },
    '$router' (to, from) {
      this.restore()
    }
  },
  mounted () {
    this.insertBanner()
  },
  methods: {
    insertBanner () {
      this.bannerHtml = ''
      // setTimeout(() => {
      //   this.bannerHtml = globalAdvertise.getAdvertise('game-square', true)
      // }, this.resolution.width > this.resolution.tablet ? 300 : 1000)
    }
  }
}
</script>